

.container-player {
    overflow: none;
    width: 100%; 
    height: 100%; 
    background-color: #eee;
    overflow: hidden;
    position: relative;

    &.fullscreen-background {
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
    }

    .container-player-outer {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .container-player-inner,
    .container-player-poster {
        position: absolute;
        z-index: 0;
    }

    .container-player-poster {
        background-size: cover;
        z-index: 0;
    }

    &.youtube, &.vimeo {
        iframe {
            opacity: 0;
        }

        &.transition-in {
            &.playing iframe,
            &.paused iframe {
                animation: revealVideo 1s;
            }
        }

        &.playing iframe,
        &.paused iframe {
            opacity: 1;
        }
    }

    .container-player-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
    }

    @keyframes revealVideo {
        0%, 50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    video, iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}